*,
*:before,
*:after {
   box-sizing: border-box;
}

body,
button,
html,
input {
   -webkit-font-smoothing: antialiased;
   word-wrap: break-word;
}

body {
   margin: 0;
}

.box {
   padding: 0.5em 1em 0.5em 1em;
}

.box-small {
   padding: 10px;
}

.box-scroll {
   padding: 1em 0 1em 1em;
}

/* Our Grid Code */

.app-layout {
   display: grid;
   height: 100vh;
   grid-template-columns: auto auto minmax(800px, 1fr);
   grid-template-rows: auto auto 1fr auto;
}

.workspace {
   background-color: #1d4c8f;
   color: #bdc9e8;
}
.channels {
   background-color: #1d4c8f;
   color: #bdc9e8;
}
.write {
   background-color: #f2f2f2;
}

.appbar {
   background-color: #ffffff;
   box-shadow: 4px 4px 4px 0px rgba(188, 188, 188, 0.2);
   grid-column: 2 / 4;
   grid-row: 1;
}

.workspace {
   grid-column: 1;
   grid-row: 1 / 5;
   overflow-y: hidden;
   margin-top: 59px;
}

.workspace:hover {
   overflow-y: auto;
}

.link {
   text-decoration: none;
   color: #457ab9;
   cursor: pointer;
}

.link:hover {
   text-decoration: underline;
}

.workspace:hover {
   overflow-y: auto;
}

.workspace::-webkit-scrollbar-track {
   background: #112044;
}
.workspace::-webkit-scrollbar-thumb {
   background: #aaa;
}
.workspace::-webkit-scrollbar-thumb:hover {
   background: #aaa;
}

.channels {
   width: 200px;
   grid-column: 1;
   grid-row: 1 / 5;
   overflow-y: hidden;
   padding-right: 0;
   padding-left: 0;
   padding-top: 7px;
   scrollbar-color: yellow blue;
   top: 0;
   list-style: none;
   position: sticky;
}

.menu-item {
   display: flex;
   padding: 0;
   margin-bottom: 0;
   cursor: pointer;
   font-family: 'Lato';
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 18px;
   text-decoration: none;
}

.image-icon {
   fill: violet;
}

.menu-item::before {
   content: '';
   position: absolute;
   left: -1.2rem;
   height: 100%;
   border-left: 2px solid #88beff;
   border-radius: 2px;
   opacity: 1;
   visibility: hidden;
}

.menu-item-text {
   margin-left: 1.5rem;
   opacity: 1;
   z-index: 1;
}

.menu-item-icon {
   font-size: 2rem;
}

.menu-item:hover {
   background-color: #173a6b;
   color: #ffffff;
}
.menu-item:hover::before {
   opacity: 1;
   visibility: unset;
}

.channels:hover {
   overflow-y: auto;
}

.channels::-webkit-scrollbar-track {
   background: #1b2a4e;
}
.channels::-webkit-scrollbar-thumb {
   background: #aaa;
}
.channels::-webkit-scrollbar-thumb:hover {
   background: #aaa;
}

.header-panel {
   grid-column: 3;
   grid-row: 2;
   color: #333;
   border-bottom: 1px solid #f2f2f2;
}

.messages {
   grid-column: 3;
   grid-row: 3;
   overflow-y: auto;
}

.write {
   grid-column: 3;
   grid-row: 4;
}

.participant {
   border-radius: 0px;
   display: inline-block;
}

video {
   width: 100%;
   object-fit: contain;
   max-width: 600px;
   display: block;
   margin: 0 auto;
}

.member-app-layout {
   display: grid;
   height: 100vh;
   grid-template-columns: 1fr;
   grid-template-rows: 1fr 1fr auto;
}

.member-contents-1 {
   grid-column: 1;
   grid-row: 1;
   overflow-y: auto;
   display: flex;
   justify-content: center;
   align-items: center;
}

.member-contents-2 {
   grid-column: 1;
   grid-row: 2;
   overflow-y: auto;
   display: flex;
   justify-content: center;
   align-items: center;
}

.member-action {
   grid-column: 1;
   grid-row: 3;
}

.positive-button {
   background-color: #18b03a !important;
   color: #ffffff !important;
   float: right !important;
}

.positive-button:hover {
   background-color: #189634 !important;
}

.negative-button {
   background-color: #d80505 !important;
   color: #ffffff !important;
   float: right !important;
}

.negative-button:hover {
   background-color: #a60b0b !important;
}

.ui.mini.search.search-box > .ui.icon.input > input {
   color: #091930;
   background-color: #eaf4ff;
   width: 366px;
   border-radius: 4px;
   border: 1px solid #eaf4ff;
   padding: 5px 8px;
   height: 34px;
   font-family: 'Lato';
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 18px;
}

.ui.mini.search.search-box > .ui.icon.input > input::placeholder {
   color: #091930;
}

.ui.mini.search.search-box > .ui.icon.input > i.icon {
   color: #091930;
   opacity: 1;
}

.ui.toggle.checkbox > input:checked ~ label:before {
   background-color: #189634 !important;
}

.ui.toggle.checkbox input:focus:checked ~ label:before {
   background-color: #189634 !important;
}

.ui.mini.input.rule-control {
   color: #1a6659 !important;
   border: 1px solid #1a6659 !important;
   min-width: 28px !important;
   width: 35px !important;
   height: 30px !important;
}

.ui.mini.input.rule-control > input {
   color: #1a6659 !important;
   text-align: center;
   min-width: 28px !important;
   padding: 0;
   border: none;
}

.modalActions {
   background: #ffffff !important;
   text-align: left !important;
}

.greyAlert {
   background-color: #f5f5f5 !important;
   border-color: #eaeaea !important;
   color: #7b7b7b !important;
   text-align: left !important;
}

.search-inbox > .ui.left.icon.input > input {
   font-weight: 400;
   font-family: 'Lato';
}

.underline {
   border-bottom: 1px solid currentColor;
   display: inline-block;
   line-height: 1.25;
   font-weight: 700;
   color: #fff;
}

.thin {
   font-weight: 300;
}

.ql-editor {
   min-height: 70px;
}

#emoji-palette {
   max-width: 400px !important;
   top: -40px !important;
   left: 200px !important;
}

#tab-panel {
   max-height: 70px !important;
}

@media print {
   .print-style {
      margin: 4em !important;
      padding: 0 !important;
      overflow: hidden;
   }
}
